import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import debounce from 'lodash/debounce';
import throttle from 'lodash/throttle';
import { useTranslation } from 'next-i18next';
import { FC, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import useDeepEffect from 'use-deep-compare-effect';

import checkMobileView from '@lib/check-mobile-view';
import { useMounted } from '@lib/hooks/useMounted';

import style from './Carousel.module.scss';

interface CarouselProps {
  currentIndex?: number;
  children: ReactNode[];
  itemPerView?: 1 | 2 | 3 | 4;
  isNoPadding?: boolean;
  className?: string;
  thumbnailContainerClassName?: string;
  identifier?: string;
  hideArrow?: boolean;
  areArrowsOutside?: boolean;
}

type Direction = 'left' | 'right';
type Position = { index: number; direction: Direction };

const createButtonThumbnail = (index: number, onClick: (index: number) => void, selectedIndex: number) => (
  <button
    type="button"
    aria-label={`Show Image ${index + 1}`}
    key={`thumbnail-${index}`}
    className={`${style.thumbnail} ${index === selectedIndex ? style.selectedThumbnail : ''}`}
    onClick={() => onClick(index)}
  >
    <span />
  </button>
);

const Carousel: FC<CarouselProps> = ({
  currentIndex = 0,
  children,
  itemPerView = 1,
  isNoPadding = false,
  className,
  thumbnailContainerClassName,
  identifier,
  hideArrow = false,
  areArrowsOutside = false,
}: CarouselProps) => {
  const { t } = useTranslation('common');

  // Refs
  const containerRef = useRef<HTMLDivElement>(null);
  const itemsContainerRef = useRef<HTMLDivElement>(null);
  const thumbnailContainerRef = useRef<HTMLDivElement>(null);

  const mounted = useMounted();
  const childrenIndex = Array.from(Array(children?.length).keys());
  const [viewWindow, setViewWindow] = useState(childrenIndex.slice(0, itemPerView));
  const [position, setPosition] = useState<Position>({
    index: currentIndex,
    direction: 'right',
  });

  // to force re-render on itemPerView, identifier changes
  const [key, setKey] = useState(identifier ?? 'carousel');
  const getKey = useCallback(() => `${identifier ?? 'carousel'}-${itemPerView}}`, [identifier, itemPerView]);

  // Utils
  const onThumbnailClick = (index: number) => {
    setPosition((prevState) => ({ ...prevState, index }));
  };

  const onArrowClick = useCallback(
    (dir: Direction) => {
      const getNextViewWindow = () => {
        const start = viewWindow[0] + 1;
        return childrenIndex.slice(start, start + itemPerView);
      };

      const getPrevViewWindow = () => {
        const start = viewWindow[0] - 1;
        return childrenIndex.slice(start, start + itemPerView);
      };

      const updatedView = dir === 'right' ? getNextViewWindow() : getPrevViewWindow();
      const index = dir === 'right' ? updatedView[0] : updatedView[updatedView.length - 1];
      setViewWindow(updatedView);
      setPosition({ index, direction: dir });
    },
    [itemPerView, viewWindow, childrenIndex]
  );

  const scrollHandler = useCallback(
    (e: Event) => {
      const updateViewWindow = (updatedView: number[]) => {
        if (updatedView?.length === itemPerView) {
          setViewWindow(updatedView);

          // TBC thumbnail pos behavior for multi items per view
          // setViewWindow((curView) => {
          //   const updatedIndex = updatedView.find((index) => curView.indexOf(index) === -1);
          //   if (typeof updatedIndex === 'number') setSelectedIndex(updatedIndex);
          //   return updatedView;
          // });

          // will hide the thumbnails for > 1 itemPerView for now
          if (itemPerView === 1) {
            setPosition((prevState) => ({ ...prevState, index: updatedView[0] }));
          }
        }
      };

      const target = e.target as HTMLElement;
      if (target) {
        const { length } = target!.children;
        if (length <= 1) {
          return;
        }

        if (!target.scrollLeft) {
          updateViewWindow(Array.from(Array(length).keys()).slice(0, itemPerView));
          return;
        }

        const childrenEl = Array.from(target!.children) as Array<HTMLElement>;
        const childrenOffset = childrenEl.map((el) => el.offsetLeft + el.clientWidth / 2);
        const lowerBound = target.scrollLeft;
        const upperBound = target.scrollLeft + target.clientWidth;

        // find items that's in view in the carousel
        const updatedView = childrenOffset.reduce((acc, curr, ind) => {
          if (curr > lowerBound && curr < upperBound) {
            acc.push(ind);
          }
          return acc;
        }, [] as number[]);

        updateViewWindow(updatedView);
      }
    },
    [itemPerView]
  );

  const doesThumbnailExceedWidth = (): boolean => {
    if (thumbnailContainerRef && containerRef) {
      const containerWidth = containerRef?.current?.clientWidth as number;
      const thumbnailWidth = thumbnailContainerRef?.current?.scrollWidth as number;
      return thumbnailWidth > containerWidth;
    }
    return false;
  };

  // flag for setting thumbnail container width not to exceed carousel container
  const [thumbnailExceeded, setThumbnailExceeded] = useState(doesThumbnailExceedWidth());
  const checkThumbnailExceedWidth = (childrenChanged = false) => {
    const alwaysCheckOnChildrenChanged = childrenChanged ? true : !thumbnailExceeded;
    if (!checkMobileView() && alwaysCheckOnChildrenChanged) {
      setThumbnailExceeded(doesThumbnailExceedWidth());
    }
  };

  // Effects
  useEffect(() => {
    checkThumbnailExceedWidth(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children?.length]);

  // need to re-register eventListener on state change to ensure getting latest updated state
  useEffect(() => {
    const throttledHandler = throttle(() => checkThumbnailExceedWidth(), 200);
    window.addEventListener('resize', throttledHandler);
    return () => window.removeEventListener('resize', throttledHandler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thumbnailExceeded]);

  useDeepEffect(() => {
    if (mounted) {
      const { index: selectedIndex, direction } = position;
      (itemsContainerRef?.current?.childNodes[selectedIndex] as HTMLElement)?.scrollIntoView({
        block: 'nearest',
        inline: direction === 'right' ? 'start' : 'end',
      });
    }
  }, [position]);

  useEffect(() => {
    if (mounted) {
      setPosition({ index: 0, direction: 'right' });
      setViewWindow(childrenIndex.slice(0, itemPerView));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identifier]);

  useEffect(() => {
    // to update viewWindow when carousel itemPerView is updated without recreating
    if (itemPerView - viewWindow.length) {
      setViewWindow(childrenIndex.slice(viewWindow[0], viewWindow[0] + itemPerView));
    }
    setKey(getKey());

    const itemsContainer = itemsContainerRef?.current as HTMLElement;
    const debouncedHandler = debounce(scrollHandler, 100);
    itemsContainer.addEventListener('scroll', debouncedHandler);
    return () => itemsContainer.removeEventListener('scroll', debouncedHandler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemPerView, getKey, itemsContainerRef?.current]);

  return (
    <div
      className={cn(
        'w-full relative px-5',
        { [style.noPadding]: isNoPadding, [style.arrowOutside]: areArrowsOutside },
        className
      )}
      ref={containerRef}
      key={key}
    >
      <div className={cn(style.canvas)}>
        <div
          className={cn(
            style.items,
            { 'justify-between': children?.length > itemPerView },
            {
              [style.twoItems]: itemPerView === 2,
              [style.threeItems]: itemPerView === 3,
              [style.fourItems]: itemPerView === 4,
            }
          )}
          dir="ltr"
          ref={itemsContainerRef}
        >
          {children}
        </div>
      </div>
      {!hideArrow && children?.length > itemPerView && (
        <>
          <button
            className={style.arrowLeft}
            aria-label={t('carousel.previous')}
            type="button"
            disabled={viewWindow[0] === 0}
            onClick={() => onArrowClick('left')}
          >
            <FontAwesomeIcon
              className="text-secondary"
              icon={['fal', 'chevron-left']}
              size="1x"
              title={t('carousel.previous')}
              aria-hidden
              focusable={false}
            />
          </button>
          <button
            className={style.arrowRight}
            aria-label={t('carousel.next')}
            type="button"
            disabled={viewWindow[viewWindow.length - 1] === children.length - 1}
            onClick={() => onArrowClick('right')}
          >
            <FontAwesomeIcon
              className="text-secondary"
              icon={['fal', 'chevron-right']}
              size="1x"
              title={t('carousel.next')}
              aria-hidden
              focusable={false}
            />
          </button>
        </>
      )}
      {/* TODO: handle thumbnail button for carousel with > 1 view window */}
      {children?.length > 1 && itemPerView === 1 && (
        <div
          className={cn(
            style.thumbnails,
            thumbnailExceeded ? style.thumbnailExceeded : '',
            thumbnailContainerClassName || ''
          )}
          ref={thumbnailContainerRef}
        >
          {Array.from({ length: children?.length }, (_, index) =>
            createButtonThumbnail(index, onThumbnailClick, position.index)
          )}
        </div>
      )}
    </div>
  );
};

export default Carousel;
