import cn from 'classnames';
import { FC } from 'react';

import { ImageAlignType } from '@components/common/enums/ImageAlignType';
import ImageWithContentItem from '@components/common/types/ImageWithContentItem';

import { ImageOrVideo } from '..';

const ImageWithContent: FC<ImageWithContentItem> = ({
  source,
  type = ImageAlignType.LEFT,
  mobileType,
  mobileBreakpoint = 'md',
  ...restProps
}) => {
  let { className } = restProps;
  const { content } = restProps;

  switch (type) {
    case ImageAlignType.LEFT:
      className = cn(
        className,
        'flex',
        {
          [`flex-col`]: mobileType === ImageAlignType.TOP,
          [`flex-col-reverse`]: mobileType === ImageAlignType.BOTTOM,
        },
        // needs safelisting https://tailwindcss.com/docs/content-configuration#dynamic-class-names
        `${mobileBreakpoint}:flex-row`
      );
      break;
    case ImageAlignType.RIGHT:
      className = cn(
        className,
        'flex',
        {
          [`flex-col`]: mobileType === ImageAlignType.TOP,
          [`flex-col-reverse`]: mobileType === ImageAlignType.BOTTOM,
        },
        `${mobileBreakpoint}:flex-row-reverse`
      );
      break;
    case ImageAlignType.BOTTOM:
      className = cn(className, 'flex flex-col flex-col-reverse');
      break;
    case ImageAlignType.TOP:
      className = cn(className, 'flex flex-col flex-col');
      break;
    case ImageAlignType.BACKGROUND:
      return (
        <div className={cn(className, 'relative')}>
          <ImageOrVideo {...source} />
          <div className="absolute inset-0 w-full h-full">{content}</div>
        </div>
      );
    default:
      return null;
  }

  if (type === ImageAlignType.RIGHT || type === ImageAlignType.LEFT) {
    return (
      <div className={className}>
        <div className={`w-full ${mobileBreakpoint}:w-1/2`}>
          <ImageOrVideo {...source} />
        </div>
        <div className={`w-full ${mobileBreakpoint}:w-1/2`}>{content}</div>
      </div>
    );
  }
  return (
    <div className={className}>
      <ImageOrVideo {...source} />
      {content}
    </div>
  );
};

export default ImageWithContent;
