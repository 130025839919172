import { FC } from 'react';

import { Renderer } from '@components/screen/factory';
import { Button, Container, Link, Text } from '@components/ui';
import { getContentfulImgUrl } from '@lib/image';

import s from './ProductCatalog.module.scss';

export interface Product {
  content: {
    name: string;
    description: string;
    url: string;
    image: {
      url: string;
      alt: string;
      description: string;
    };
  };
}

export interface ProductCatalogProps {
  header: string;
  subheader: string;
  url: string;
  products: Product[];
  cta: string;
}

const ProductElements: FC<{ products: Product[] }> = ({ products }) => {
  return (
    <div className={s.productContainer}>
      {products.map((product, index) => (
        <div key={product.content.name} className={s.details}>
          <Link href={product.content.url} aria-labelledby={`product-name-${index}`}>
            <div className="flex justify-center pb-6">
              <img
                className={s.image}
                src={getContentfulImgUrl(product.content.image.url, 220)}
                alt={product.content.image.alt}
                loading="lazy"
              />
            </div>

            <Text id={`product-name-${index}`} className={s?.name}>
              {product?.content?.name}
            </Text>
            <Text className={s?.description}>{product?.content?.description}</Text>
          </Link>
        </div>
      ))}
    </div>
  );
};

const ProductCatalog: FC<ProductCatalogProps> = ({ header, subheader, products, url, cta }) => {
  return (
    <div className={s.container}>
      <Container mobilePadding>
        <div className="py-8 md:pt-24 md:pb-8">
          <Text color="var(--eggplant)" asElement="p" variant="eyebrow" className="uppercase text-center pb-4">
            {header}
          </Text>
          <h2 className={s.subheader}>{subheader}</h2>
          <ProductElements products={products} />
          <div className="flex justify-center py-4">
            <Button className="catalogCta" variant="cta" href={url}>
              {cta}
            </Button>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Renderer({ name: 'moduleProductCatalog' })(ProductCatalog);
