import cn from 'classnames';
import React, { forwardRef, LegacyRef, MutableRefObject } from 'react';

import style from './HeroCarousel.module.scss';

interface Props {
  className?: string;
}

const SwiperPagination = forwardRef(({ className }: Props, ref: MutableRefObject<any> | LegacyRef<any>) => (
  <div ref={ref} className={cn(style.materialPagination, 'swiper-pagination', className)} />
));

SwiperPagination.displayName = 'SwiperPagination';

export default SwiperPagination;
