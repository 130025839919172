import { faAngleDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { FC, forwardRef, MutableRefObject, SelectHTMLAttributes, useCallback, useRef, useState } from 'react';
import { FieldError } from 'react-hook-form';

import Text from '../Text';

import style from './Select.module.scss';

export interface SelectOption {
  key: string;
  value: string;
}

export interface Props extends SelectHTMLAttributes<HTMLSelectElement> {
  className?: string;
  id: string;
  onChange?: (...args: any[]) => any;
  label: string;
  placeholder: string;
  helperText?: string;
  error?: FieldError;
  options: SelectOption[];
  readonly?: boolean;
  onBlur?: (e: any) => void;
  'data-cy'?: string;
  toggleAutofill?: boolean;
}

function isMutableRefObject(ref: any): ref is MutableRefObject<any> {
  return !!ref?.current;
}

const Select: FC<Props> = forwardRef<HTMLSelectElement, Props>((props, ref) => {
  const {
    className,
    children,
    onChange,
    onBlur,
    label,
    id: inputId,
    defaultValue,
    placeholder,
    helperText,
    options,
    error,
    disabled,
    toggleAutofill,
    ...rest
  } = props;
  const rootClassName = cn(style.root, className);

  const inputRef = useRef<HTMLSelectElement>(null);
  const [focus, setFocus] = useState(false);
  const value = inputRef.current?.value || defaultValue;

  const callbackRef = useCallback(
    (element: HTMLSelectElement | null) => {
      (inputRef as any).current = element;
      if (ref && !isMutableRefObject(ref)) {
        ref(element);
      }
    },
    [ref]
  );

  const handleOnChange = (e: any) => {
    if (onChange) {
      setFocus(false);
      onChange(e);
    }
  };

  const handleOnBlur = (e: any) => {
    setFocus(false);
    if (onBlur) {
      onBlur(e);
    }
  };

  const isFocused = focus || !!value || !!error;

  return (
    <div className={cn(rootClassName, toggleAutofill && style.autofillFocusedLabels)}>
      {label && (
        <label
          className={cn(
            style.label,
            { [style.focusedLabel]: isFocused },
            { [style.isError]: !!error },
            { [style.isDisabled]: !!disabled }
          )}
          htmlFor={inputId}
        >
          {label}
        </label>
      )}
      <select
        id={inputId}
        ref={callbackRef}
        className={cn(
          style.select,
          style.input,
          { [style.focusedInput]: isFocused, [style.withoutLabel]: label },
          { [style.errorInput]: !!error },
          { [style.isDisabled]: !!disabled || (placeholder !== null && value === '') }
        )}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        onFocus={() => setFocus(true)}
        defaultValue={defaultValue}
        disabled={disabled}
        {...rest}
      >
        {placeholder && (
          <option value="" disabled>
            {placeholder}
          </option>
        )}
        {options.map(({ key, value: val }) => (
          <option key={`${val}_${key}`} value={key}>
            {val}
          </option>
        ))}
      </select>
      <FontAwesomeIcon
        className={cn(style.caret, 'text-placeholder', { 'text-primary': isFocused })}
        icon={faAngleDown}
        size="1x"
        title="select icon"
      />
      {(helperText || error?.message) && (
        <Text
          variant="text-4"
          data-cy={rest['data-cy'] ? `${rest['data-cy']}-helper-text` : undefined}
          className={cn(style.helperText, { [style.isError]: !!error })}
          aria-live="assertive"
        >
          {helperText || error?.message}
        </Text>
      )}
    </div>
  );
});

Select.displayName = 'Select';

export default Select;
