import { useRouter } from 'next/router';
import { i18n } from 'next-i18next.config';

import { isPremiumSKU } from '@components/cart/helpers';
import useCart from '@framework/cart/use-cart';

export const useHasSubscriptionInCart = (): boolean => {
  const { data: cart } = useCart();
  const { locale = i18n.defaultLocale } = useRouter();

  return !!cart?.lineItems?.map((item) => item.variant.sku)?.some((sku) => isPremiumSKU(sku, locale));
};
