import type { ExperienceComponent } from '@ninetailed/experience.js-next';

import type { RendererConfig } from './types';

type Props = {
  locale: string;
  currencyCode: string;
  variant?: RendererConfig;
  baseline: RendererConfig;
};

export const ModuleExperienceSelector: ExperienceComponent<Props> = ({
  locale,
  currencyCode,
  variant,
  baseline,
  ...props
}) => {
  if (variant) {
    return <variant.component locale={locale} currencyCode={currencyCode} {...(variant.mapper?.(props) ?? props)} />;
  }
  return <baseline.component locale={locale} currencyCode={currencyCode} {...props} />;
};
