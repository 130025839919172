import Teaser from 'components/common/Teaser';
import { FC } from 'react';

import DownloadApp from '@components/common/DownloadApp';
import { BackgroundColor } from '@components/common/Teaser/Teaser';
import { Renderer } from '@components/screen/factory';

import { DownloadAppTeaser as DownloadAppTeaserProps } from '../types/DownloadAppTeaser';

const DownloadAppTeaser: FC<DownloadAppTeaserProps> = ({
  internalName,
  title,
  headline,
  paragraph,
  iosLink,
  androidLink,
}) => (
  <Teaser
    internalName={internalName}
    title={title}
    headline={headline}
    paragraph={paragraph}
    backgroundColor={BackgroundColor.LightGray}
  >
    <DownloadApp className="mt-xxl" iosLink={iosLink} androidLink={androidLink} />
  </Teaser>
);

export default Renderer({ name: 'moduleDownloadAppTeaser' })(DownloadAppTeaser);
