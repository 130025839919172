import cn from 'classnames';
import { useTranslation } from 'next-i18next';
import React, { FC } from 'react';

import { Button } from '@components/ui';
import { getContentfulImgUrl, renderImage } from '@lib/image';

import { ModulePartner } from '../types/ModulePartner';

import style from './Partner.module.scss';

interface Props extends ModulePartner {
  className?: string;
}

const Partner: FC<Props> = ({ className, logo, description, internalName, ctaUrl }) => {
  const { t } = useTranslation(['common']);
  // this happens if a module is in draft mode, we just get back a sys link
  // so rather than render an empty block, return out null
  if (!internalName) {
    return null;
  }
  return (
    <div
      className={cn(
        style.root,
        className,
        'border border-2 border-outline-primary px-6 py-7 md:py-10 inline-flex flex-col'
      )}
    >
      <div className={style.imageWrapper}>
        {renderImage(
          { url: getContentfulImgUrl(logo?.url, 240), alt: internalName },
          { className: 'absolute top-0 left-1/2 transform -translate-x-1/2 max-h-full' }
        )}
      </div>
      <div className={style.description}>{description}</div>
      <div className="text-center">
        <Button variant="cta" href={ctaUrl} aria-label={t('common:visit')} className="md:text-lg">
          {t('common:visit')}
        </Button>
      </div>
    </div>
  );
};

export default Partner;
