import cn from 'classnames';
import React, { FC } from 'react';

import { ImageAlignType } from '@components/common/enums/ImageAlignType';
import ImageOrVideoItem from '@components/common/types/ImageOrVideoItem';
import { ImageWithContent, Text } from '@components/ui';

import s from './Logo.module.scss';

interface Props {
  source: ImageOrVideoItem;
  className?: string;
  name: string;
  showName: boolean;
  url: string;

  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

const Logo: FC<Props> = ({ source, className, name, showName, url, onMouseEnter, onMouseLeave }) => (
  <ImageWithContent
    className={cn(s.root, className)}
    source={{
      ...source,
      className: cn('absolute inset-1/2 transform -translate-x-1/2 -translate-y-1/2', source.className),
    }}
    type={ImageAlignType.BACKGROUND}
    content={
      <div className={cn('w-full h-full', source.className)}>
        <a
          href={url}
          className={s.contentRoot}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          aria-label={!showName ? name : undefined}
        >
          {showName && (
            <Text variant="heading-3" className={s.name}>
              {name}
            </Text>
          )}
        </a>
      </div>
    }
  />
);

export default Logo;
