import { Entry } from '@services/contentful/types';
import { FC } from 'react';

import { ModulePlan, ModulePlanContent } from '@components/common/types/ModulePlan';
import { Renderer } from '@components/screen/factory';
import { useAddToCart } from '@lib/hooks/useAddToCart';

import { getPlansWithoutContentObject } from '../helpers';
import ChoosePlans from './ChoosePlans';

interface RendererProps {
  title: string;
  currencyCode: string;
  choosePlanCta: string;
  plans: Entry<ModulePlanContent>[];
}

type Props = Omit<RendererProps, 'plans'> & {
  plans: ModulePlan[];
};

const ChoosePlansRenderer: FC<Props> = ({ title, plans, currencyCode, choosePlanCta }) => {
  const [loading, onAddToCart] = useAddToCart();

  return (
    <ChoosePlans
      plans={plans}
      title={title}
      ctaText={choosePlanCta}
      onAddToCart={onAddToCart}
      currencyCode={currencyCode}
      loading={loading}
    />
  );
};

const mapper = ({ plans, ...rest }: RendererProps): Props => ({
  ...rest,
  plans: getPlansWithoutContentObject(plans),
});

export default Renderer({ name: 'moduleChoosePlan', mapper })(ChoosePlansRenderer);
