import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { FC, ReactNode, useState } from 'react';
import { Config, TriggerType, usePopperTooltip } from 'react-popper-tooltip';

import 'react-popper-tooltip/dist/styles.css';
import style from './Tooltip.module.scss';

type Placement =
  | 'auto'
  | 'auto-end'
  | 'auto-start'
  | 'top'
  | 'top-end'
  | 'top-start'
  | 'left'
  | 'left-end'
  | 'left-start'
  | 'right'
  | 'right-end'
  | 'right-start'
  | 'bottom'
  | 'bottom-end'
  | 'bottom-start';

interface TooltipProps {
  tooltipContent: ReactNode;
  children: ReactNode;
  trigger?: TriggerType;
  className?: string;
  contentClassName?: string;
  arrowClassName?: string;
  placement?: Placement;
  showCloseButton?: boolean;
}

const Tooltip: FC<TooltipProps> = ({
  tooltipContent,
  trigger = 'click',
  placement = 'auto',
  children,
  className,
  contentClassName,
  showCloseButton,
  arrowClassName,
}: TooltipProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const options: Config = {
    trigger,
    placement,
    visible: isVisible,
    onVisibleChange: setIsVisible,
    closeOnTriggerHidden: false,
  };

  if (showCloseButton) {
    // setting this to true will cause onBlur to close tooltip even though trigger is `click`
    // current workaround is to set it as false with css `pointer-events` override
    options.interactive = false;
    options.closeOnOutsideClick = false;
  }

  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible } = usePopperTooltip(options);

  return (
    <>
      <span ref={setTriggerRef} className={className}>
        {children}
      </span>
      {visible && (
        <div
          role="tooltip"
          ref={setTooltipRef}
          aria-label="learn more tooltip"
          {...getTooltipProps({
            className: cn(style.tooltipContainer, contentClassName, 'tooltip-container'),
          })}
        >
          <div
            {...getArrowProps({
              className: `${arrowClassName} tooltip-arrow ${style.tooltipArrow}`,
            })}
          />
          {showCloseButton && (
            <button
              type="button"
              className={style.closeButton}
              onClick={() => setIsVisible(false)}
              aria-label="close tooltip"
            >
              <FontAwesomeIcon className="mr-xs" icon={faTimes} size="lg" />
            </button>
          )}
          {tooltipContent}
        </div>
      )}
    </>
  );
};

export default Tooltip;
