import type { Entry } from '@services/contentful/types';
import { useEffect, useMemo } from 'react';

import * as componentsDynamic from '@components/dynamic';
import { resolveRenderer } from '@components/screen/factory';

import { mapModuleExperiences } from './mapExperience';
import type { NtConfigurable } from './types';

const initDynamicComponents = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  (function doNothing(components: any): void {
    // do nothing
    // this function is just to ensure renderer config for dynamic components are updated
  })(componentsDynamic);
};

export function useDynamicComponents(entries: Entry<NtConfigurable>[] = []) {
  useEffect(() => {
    initDynamicComponents();
  }, []);

  const components = useMemo(
    () =>
      entries
        .filter((entry) => entry.content)
        .map((entry) => {
          const rendererConfig = resolveRenderer(entry.contentType);
          const experiences = mapModuleExperiences(entry.content.nt_experiences);

          return {
            // Added ! operator to avoid add more types, it is filtered below
            Component: rendererConfig?.component!,
            mapper: rendererConfig?.mapper,
            props: entry.content,
            id: entry.id,
            experiences,
          };
        })
        .filter(({ Component }) => !!Component),
    [entries]
  );

  return components;
}
