import { Entry } from '@services/contentful/types';
import cn from 'classnames';
import { FC } from 'react';

import { Image } from '@commerce/types/common';
import { Renderer } from '@components/screen/factory';
import { Container, Link, Text } from '@components/ui';
import { getContentfulImgUrl, renderImage } from '@lib/image';

import s from './Seatbelt.module.scss';

interface Item {
  internalName: string;
  image: Image;
  title: string;
  ctaUrl: string;
  ctaOpenNewTab?: boolean;
}

interface Props {
  items: Entry<Item>[];
  title: string;
}

const Seatbelt: FC<Props> = ({ title, items }) => (
  <Container>
    <div className={s.wrapper}>
      <Text variant="base-bold" className={cn(s.eyebrow, 'mb-4 text-center')} asElement="h2">
        {title}
      </Text>
      <div className={s.seatbeltWrapper}>
        {items.map(({ content: { internalName, image, title: itemTitle, ctaOpenNewTab = false, ctaUrl } }) => (
          <Link
            target={ctaOpenNewTab ? '_blank' : '_self'}
            href={ctaUrl}
            key={internalName}
            className={s.item}
            data-cy="seatbelt-item"
          >
            {image?.url
              ? renderImage(
                  { url: getContentfulImgUrl(image.url, 140), alt: image.alt || itemTitle },
                  { className: 'w-full' }
                )
              : null}
            <Text variant="text-4" className={s.itemTitle}>
              {itemTitle}
            </Text>
          </Link>
        ))}
      </div>
    </div>
  </Container>
);

export default Renderer({ name: 'moduleSeatbelt' })(Seatbelt);
