export enum HorizontalAlignment {
  Center = 'Center',
  Left = 'Left',
  Right = 'Right',
}

export enum VerticalAlignment {
  Center = 'Center',
  Top = 'Top',
  Bottom = 'Bottom',
}

export interface Hero {
  internalName?: string;
  h2?: string;
  title: string;
  buttonText: string;
  ctaUrl: string;
  horizontalAlignment?: HorizontalAlignment;
  mobileHorizontalAlignment?: HorizontalAlignment;
  verticalAlignment?: VerticalAlignment;
  mobileVerticalAlignment?: VerticalAlignment;
  banner: {
    alt: string;
    url: string;
  };
  mobileBanner: {
    alt: string;
    url: string;
  };
}
