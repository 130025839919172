import Script from 'next/script';
import { i18n } from 'next-i18next.config';
import { FC, useEffect, useState } from 'react';
import { InView } from 'react-intersection-observer';

import { TURN_TO_SUMMARY_ELEMENT_ID } from '@components/product/constants';
import { originalLocaleFormat } from '@lib/locales';

interface Props {
  sku?: string;
  locale?: string;
  pathname?: string;
}

const ReviewWidget: FC<Props> = ({ sku, locale, pathname }) => {
  const siteKey = process.env.NEXT_PUBLIC_TURNTO_SITEKEY;
  const [isInit, setIsInit] = useState(false);

  useEffect(() => {
    if (typeof window !== undefined) {
      const formattedLocale = originalLocaleFormat(locale || i18n.defaultLocale).replace('-', '_');
      if ((window as any).turnToConfig) {
        // refresh sku
        // https://docs.turnto.com/en/speedflex-widget-implementation/speedflex-widgets/review-display-widgets.html#widget-refresh-function-14757
        (window as any).TurnToCmd('set', { sku, locale: formattedLocale });
      } else {
        (window as any).turnToConfig = {
          locale: formattedLocale,
          pageId: 'pdp-page',
          // TurnTo widget init will inject Product JSON-LD by default :(
          // as we already inject our own product json ld
          // workaround is by renaming the turnto injected schema into something else
          schemaOrgEntityType: 'TurnTo',
          sku,
        };
      }

      (window as any).TurnToCmd =
        (window as any).TurnToCmd ||
        // eslint-disable-next-line func-names
        function () {
          // eslint-disable-next-line prefer-rest-params
          ((window as any).TurnToCmd.q = (window as any).TurnToCmd.q || []).push(arguments);
        };

      const event = new Event('rendered');
      document.dispatchEvent(event);
    }
  }, [locale, sku, pathname]);

  const startWidgets = () => {
    if ((window as any).TurnToCmd) {
      (window as any).TurnToCmd('initialize', 'reviews-summary');
      (window as any).TurnToCmd('initialize', 'reviews-list');
      setIsInit(true);
    }
  };

  return siteKey ? (
    <>
      <Script src={`https://widgets.turnto.com/v5/widgets/${siteKey}/js/turnto.js`} strategy="lazyOnload" />
      <InView onChange={startWidgets} skip={isInit}>
        <section className="px-5 md:px-0">
          <div id={TURN_TO_SUMMARY_ELEMENT_ID} />
          <div id="tt-reviews-list" />
        </section>
      </InView>
    </>
  ) : null;
};

export default ReviewWidget;
