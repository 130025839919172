import 'swiper/css/pagination';

import cn from 'classnames';
import { useTranslation } from 'next-i18next';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';

import { Image, Video } from '@commerce/types/common';
import { Renderer } from '@components/screen/factory';
import { ImageOrVideo, Text } from '@components/ui';
import { FontSizes, FontTypes } from '@components/ui/types/ContentfulTextOption';
import { trackEvent } from '@lib/gtag';
import EffectMaterial from '@lib/swiper/effects/effect-material.esm';
import { isVideo } from '@lib/video';

import Button from '../Button/Button';
import SwiperPagination from './SwiperPagination';

import style from './HeroCarousel.module.scss';

interface SlideContent {
  id: string;
  content: {
    internalName: string;
    header?: string;
    customHeader?: string;
    subheader?: string;
    customSubheader?: string;
    primaryCta?: string;
    primaryUrl?: string;
    secondaryCta?: string;
    secondaryUrl?: string;
    alignment?: 'Left' | 'Center' | 'Right';
    media: Image | Video;
    mobileMedia: Image | Video;
    videoThumbnail: Image;
    mobileVideoThumbnail: Image;
    isBlackText?: boolean;
    headerFontSize?: FontSizes;
    headerFontType?: FontTypes;
    primaryCtaColor: 'Purple' | 'Black' | 'White';
  };
}
interface ModuleOverview {
  interval: number;
  slides: SlideContent[];
}

const HeroCarousel: FC<ModuleOverview> = ({ slides, interval }) => {
  const sliderRef = useRef<SwiperRef | null>(null);
  const paginationRef = useRef<HTMLDivElement | null>(null);
  const [activeSlide, setActiveSlide] = useState(0);

  const { t } = useTranslation(['common']);

  const onAutoplayTimeLeft = useCallback(
    (s: any, time: any, progress: number) => {
      paginationRef.current?.style.setProperty('--progress', `${100 * (1 - progress)}%`);
    },
    [paginationRef]
  );

  const handleSlideChange = () => {
    if (sliderRef.current?.swiper.realIndex !== activeSlide) {
      const currentIndex = sliderRef.current?.swiper.realIndex;
      trackEvent({
        action: 'Hero Carousel Slide View',
        category: slides[currentIndex as number].content.internalName,
        label: slides[currentIndex as number].content.header,
      });
      setActiveSlide(currentIndex as number);
    }
  };

  // this is just to log the initial slide
  useEffect(() => {
    trackEvent({
      action: 'Hero Carousel Slide View',
      category: slides[0].content.internalName,
      label: slides[0].content.header,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // this is to run animations loop properly if less than 5 slides are set
  if (slides.length < 5) {
    // eslint-disable-next-line no-param-reassign
    slides = slides.concat(slides);
  }

  return (
    <div className={style.carouselSection}>
      <Swiper
        ref={sliderRef}
        className={style.swiperMaterial}
        modules={[Autoplay, Pagination, EffectMaterial, Navigation]}
        effect="material"
        spaceBetween={10}
        slidesPerView={1.15}
        speed={900}
        centeredSlides
        loop
        grabCursor
        navigation={{
          prevEl: '.hero-prev',
          nextEl: '.hero-next',
        }}
        pagination={{
          el: `.${style.materialPagination}`,
          clickable: true,
          bulletActiveClass: style.materialPaginationBulletActive,
          renderBullet(index, className) {
            return `<span class="${cn(style.materialPaginationBullet, className)}"><div class="${
              style.wormLoader
            }"></div></span>`;
          },
        }}
        autoplay={{
          disableOnInteraction: false,
          delay: interval || 4000,
          pauseOnMouseEnter: true,
        }}
        breakpoints={{
          1080: {
            slidesPerView: 2,
          },
        }}
        onAutoplayTimeLeft={onAutoplayTimeLeft}
        onSlideChange={handleSlideChange}
      >
        {slides.map((slide, index) => {
          return (
            <SwiperSlide key={`slide-${slide.id + index}`}>
              <div className={cn(style.swiperMaterialWrapper, 'swiper-material-wrapper')}>
                <div className={style.swiperMaterialContent}>
                  {slide.content.media && (
                    <ImageOrVideo
                      {...slide.content.media}
                      className={cn(style.materialCarouselMedia, 'hidden sm:block')}
                      data-swiper-material-scale={1.25}
                      type={isVideo(slide.content.media.type) ? slide.content.media.type! : 'image'}
                      thumbnail={slide.content.videoThumbnail?.url || undefined}
                      renderAsThumbnail={!!isVideo(slide.content.media.type)}
                      playOnActive={isVideo(slide.content.media.type) && activeSlide === index}
                    />
                  )}
                  {slide.content.mobileMedia && (
                    <ImageOrVideo
                      {...slide.content.mobileMedia}
                      className={cn(style.materialCarouselMedia, 'visible sm:invisible')}
                      type={isVideo(slide.content.media.type) ? slide.content.media.type! : 'image'}
                      thumbnail={slide.content.mobileVideoThumbnail?.url || undefined}
                      renderAsThumbnail={!!isVideo(slide.content.media.type)}
                      playOnActive={isVideo(slide.content.media.type) && activeSlide === index}
                    />
                  )}
                  <div
                    className={cn(
                      style.materialCarouselCopy,
                      slide.content.alignment === 'Center' ? 'items-center text-center' : 'items-start',
                      'swiper-material-animate-opacity'
                    )}
                    style={{ color: slide.content.isBlackText ? 'var(--black)' : 'var(--white)' }}
                  >
                    {slide.content.customHeader && (
                      <Text
                        className={cn(
                          style.slideHeader,
                          style[slide.content.headerFontSize || FontSizes.L],
                          style[slide.content.headerFontType || FontTypes.Normal]
                        )}
                        html={index === 0 ? `<h1>${slide.content.customHeader}</h1>` : `${slide.content.customHeader}`}
                      />
                    )}
                    {slide.content.header && (
                      <Text
                        className={cn(
                          style.slideHeader,
                          style[slide.content.headerFontSize || FontSizes.L],
                          style[slide.content.headerFontType || FontTypes.Normal]
                        )}
                        html={index === 0 ? `<h1>${slide.content.header}</h1>` : `<h2>${slide.content.header}</h2>`} // allows html tags
                      />
                    )}
                    {slide.content.subheader && !slide.content.customSubheader && (
                      <Text className={style.subHeader} variant="base">
                        {slide.content.subheader}
                      </Text>
                    )}
                    {slide.content.customSubheader && (
                      <Text className={style.subHeader} variant="base" html={slide.content.customSubheader} />
                    )}
                    <div className={style.materialCarouselCtas}>
                      {slide.content.primaryCta && (
                        <Button
                          className={cn(
                            style.primaryCta,
                            style[`primaryCta${slide.content.primaryCtaColor || 'Purple'}`]
                          )}
                          variant="cta"
                          href={slide.content.primaryUrl}
                          gtm={{
                            action: 'Hero Carousel Primary CTA',
                            category: `${slide.content.internalName} > ${slide.content.primaryCta}`,
                            label: slide.content.primaryUrl,
                          }}
                        >
                          {slide.content.primaryCta}
                        </Button>
                      )}
                      {slide.content.secondaryCta && (
                        <Button
                          className={style.secondaryCta}
                          variant="cta"
                          href={slide.content.secondaryUrl}
                          gtm={{
                            action: 'Hero Carousel Secondary CTA',
                            category: `${slide.content.internalName} > ${slide.content.secondaryCta}`,
                            label: slide.content.secondaryUrl,
                          }}
                        >
                          {slide.content.secondaryCta}
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
      <button
        type="button"
        className={cn(style.navButtonPrev, 'hero-prev absolute left-0 cursor-pointer z-10')}
        aria-label={t('common:screenReader.previousSlide')}
      />
      <button
        type="button"
        className={cn(style.navButtonNext, 'hero-next absolute right-0 cursor-pointer z-10')}
        aria-label={t('common:screenReader.nextSlide')}
      />
      <SwiperPagination ref={paginationRef} />
    </div>
  );
};

export default Renderer({ name: 'moduleHeroCarousel' })(HeroCarousel);
