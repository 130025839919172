import { z } from 'zod';

const ModuleFeaturedPlanSchema = z.object({
  imageOverlayHeadline: z.optional(z.string()),
  imageOverlayTitle: z.optional(z.string()),
  leftBg: z.object({ url: z.string(), alt: z.optional(z.string()), description: z.optional(z.string()) }),
  sectionOneHeadline: z.string(),
  sectionOneTitle: z.string(),
  sectionOneParagraph: z.string(),
  sectionOneCtaurl: z.string(),
  sectionOneCta: z.string(),
  sectionTwoHeadline: z.optional(z.string()),
  sectionTwoTitle: z.optional(z.string()),
  sectionTwoParagraph: z.optional(z.string()),
  sectionTwoCtaurl: z.optional(z.string()),
  sectionTwoCta: z.optional(z.string()),
});

export type ModuleFeaturedPlan = z.infer<typeof ModuleFeaturedPlanSchema>;

export const isValidModuleFeaturedPlan = (prop: ModuleFeaturedPlan) => ModuleFeaturedPlanSchema.safeParse(prop).success;
