import { Entry } from '@services/contentful/types';

import { ModulePlan, ModulePlanContent } from '@components/common/types/ModulePlan';
import { mapContent } from '@components/screen/helpers';

export const getPlansWithoutContentObject = (plans: Entry<ModulePlanContent>[]): ModulePlan[] =>
  plans.map((item) => {
    const { monthlyProduct, yearlyProduct, ...contentObject } = item.content;

    // TODO: check if monthlyProduct and yearlyProduct can be undefined
    return { ...contentObject, monthlyProduct: mapContent(monthlyProduct)!, yearlyProduct: mapContent(yearlyProduct)! };
  });
