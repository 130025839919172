import cn from 'classnames';
import { FC } from 'react';

import { Button, ImageWithContent, Text } from '@components/ui';

import { ImageAlignType } from '../enums/ImageAlignType';
import { ModuleFiftyFiftyColumnsTextAndImage } from '../types/ModuleFiftyFiftyColumnsTextAndImage';

import s from './FiftyFiftyColumnsTextAndImage.module.scss';

interface Props extends ModuleFiftyFiftyColumnsTextAndImage {
  internalName?: string;
  className?: string;
  ctaText?: string;
  ctaUrl?: string;
}

const FiftyFiftyColumnsTextAndImage: FC<Props> = ({
  internalName,
  image,
  title,
  description,
  ctaText,
  ctaUrl,
  imagePosition,
  lazyLoadImage = false,
  className,
}) => {
  return (
    <div className={cn(className)} key={internalName}>
      <ImageWithContent
        source={{
          type: 'image',
          url: image?.url || '',
          alt: image?.description || image?.alt || title,
          loading: lazyLoadImage ? 'lazy' : 'eager',
          className: 'rounded-xl',
        }}
        type={imagePosition}
        mobileType={ImageAlignType.TOP}
        content={
          <section className="flex items-center justify-center h-full">
            <div className="text-center lg:text-left lg:max-w-sm">
              <Text variant="heading-3" className={cn(s.title, 'mt-4 lg:mt-0')}>
                {title}
              </Text>
              <Text variant="text-2" html={description} className={s.description} />
              {ctaText && ctaUrl && (
                <Button variant="cta" href={ctaUrl} className="mt-6">
                  {ctaText}
                </Button>
              )}
            </div>
          </section>
        }
      />
    </div>
  );
};

export default FiftyFiftyColumnsTextAndImage;
