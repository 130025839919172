import { Entry } from '@services/contentful/types';
import cn from 'classnames';
import { FC } from 'react';

import { Renderer } from '@components/screen/factory';
import { Button, Container, Text } from '@components/ui';
import { renderImage } from '@lib/image';

import s from './FeaturedReviews.module.scss';

interface Review {
  comment: string;
  authorFirstName: string;
  variation: string;
}

interface FeaturedReviewsProps {
  title: string;
  footerTitle?: string;
  cta?: string;
  url?: string;
  subtitle: string;
  subtitleMetric: string;
  subtitleEnd: string;
  moduleReviews: Entry<Review>[];
  useWhiteBg?: boolean;
}

const iconUrl: Record<string, string> = {
  paw: '/svgs/review/paw_icon.svg',
  key: '/svgs/review/key_icon.svg',
  briefcase: '/svgs/review/briefcase_icon.svg',
  quotes: '/svgs/review/quotes_icon.svg',
};

const Reviews: FC<{ reviews: Entry<Review>[] }> = ({ reviews }) => {
  return (
    <div className="flex flex-col md:flex-row gap-8 md:gap-16 mb-8 mt-12">
      {reviews.map((review: Entry<Review>) => (
        <div key={review.content.authorFirstName} className={s.reviewContainer}>
          {iconUrl[review.content.variation] && (
            <div className={s.reviewIcon}>
              {renderImage(
                { url: iconUrl[review.content.variation], alt: review.content.comment },
                { 'aria-hidden': true, loading: 'lazy' }
              )}
            </div>
          )}
          <Text className={s.review} html={review.content.comment} />
          <Text variant="header-5" className="mt-3" color="var(--dark-purple)">
            — {review.content.authorFirstName}
          </Text>
        </div>
      ))}
    </div>
  );
};

const FeaturedReviews: FC<FeaturedReviewsProps> = ({
  title,
  footerTitle,
  cta,
  url,
  subtitle,
  subtitleMetric,
  subtitleEnd,
  moduleReviews,
  useWhiteBg = false,
}) => {
  return (
    <div className={cn(s.container, useWhiteBg ? 'bg-white' : 'bg-bone')}>
      <Container mobilePadding>
        <Text color="var(--eggplant)" className={cn(s.headlines, s.top)} variant="eyebrow">
          {title}
        </Text>
        <div className={s.subheaderContainer}>
          <Text className={cn('text-primary', s.subheader)} variant="header-4">
            {subtitle} <span className="text-purple">{subtitleMetric}</span> {subtitleEnd}
          </Text>
        </div>

        <Reviews reviews={moduleReviews} />
        {footerTitle && (
          <Text color="var(--eggplant)" className={s.headlines} variant="eyebrow">
            {footerTitle}
          </Text>
        )}
        {cta && url && (
          <div className="flex justify-center">
            <Button variant="cta" href={url}>
              {cta}
            </Button>
          </div>
        )}
      </Container>
    </div>
  );
};

export default Renderer({ name: 'moduleFeaturedCustomerReviews' })(FeaturedReviews);
