import { Entry } from '@services/contentful/types';
import { FC } from 'react';

import { ModuleProduct } from '@components/common/types/ModuleProduct';
import { Teaser } from '@components/common/types/Teaser';
import { FeaturedProduct } from '@components/homePage';
import { Renderer } from '@components/screen/factory';
import { Container } from '@components/ui';

interface Props {
  internalName?: string;
  currencyCode?: string;
  locale: string;
  moduleTeaser?: Entry<Teaser>;
  moduleProduct?: Entry<ModuleProduct>;
}

const FeatureProductRenderer: FC<Props> = ({ internalName, currencyCode, locale, moduleProduct, moduleTeaser }) => (
  <Container>
    <FeaturedProduct
      currencyCode={currencyCode}
      locale={locale}
      internalName={internalName}
      moduleProduct={moduleProduct?.content}
      moduleTeaser={moduleTeaser?.content}
    />
  </Container>
);

export default Renderer({ name: 'moduleFeaturedProduct' })(FeatureProductRenderer);
