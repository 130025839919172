import { FC } from 'react';

import { ImageAlignType } from '@components/common/enums/ImageAlignType';
import { isValidModuleFeaturedPlan, ModuleFeaturedPlan } from '@components/common/types/ModuleFeaturedPlan';
import { SubscriptionTier } from '@components/product/enums';
import { Renderer } from '@components/screen/factory';
import { Container, ImageWithContent, Text } from '@components/ui';

import PremiumPlanItem from './PremiumPlanItem';

import s from './PremiumPlans.module.scss';

const PremiumPlans: FC<ModuleFeaturedPlan> = (props) => {
  const {
    leftBg,
    imageOverlayHeadline,
    imageOverlayTitle,
    sectionOneHeadline,
    sectionOneTitle,
    sectionOneParagraph,
    sectionOneCtaurl,
    sectionOneCta,
    sectionTwoCta,
    sectionTwoCtaurl,
    sectionTwoHeadline,
    sectionTwoParagraph,
    sectionTwoTitle,
  } = props;
  const hasProtect = sectionTwoHeadline && sectionTwoTitle;

  if (!sectionOneCta && !sectionOneHeadline && !sectionOneTitle && !leftBg) {
    return null;
  }

  if (!isValidModuleFeaturedPlan(props)) {
    return null;
  }

  return (
    <Container>
      <div className="flex flex-wrap md:py-4 md:mt-4">
        <ImageWithContent
          source={{
            type: 'image',
            url: leftBg?.url,
            alt: leftBg?.description || leftBg?.alt || imageOverlayHeadline,
            className: 'w-full h-full object-cover',
            loading: 'lazy',
          }}
          type={ImageAlignType.BACKGROUND}
          className="md:w-1/2 md:pr-2"
          content={
            <div className="p-4 md:p-8 max-w-[320px] ">
              <Text color="var(--purple)" variant="heading-3" className={s.imageOverlayHeadline} asElement="h2">
                {imageOverlayHeadline}
              </Text>
              <Text variant="heading-2" className={s.imageOverlayTitle} asElement="p">
                {imageOverlayTitle}
              </Text>
            </div>
          }
        />

        <div className="flex flex-wrap md:w-1/2 mt-1 md:mt-0 md:pl-2">
          {sectionOneHeadline && sectionOneCtaurl && (
            <div className={`w-full ${hasProtect && 'md:w-1/2'} md:pr-2`}>
              <PremiumPlanItem
                tier={SubscriptionTier.PREMIUM}
                headLine={sectionOneHeadline}
                title={sectionOneTitle}
                paragraph={sectionOneParagraph}
                ctaUrl={sectionOneCtaurl}
                ctaText={sectionOneCta}
              />
            </div>
          )}
          {hasProtect && (
            <div className="w-full md:w-1/2 md:pl-2 mt-2 md:mt-0">
              <PremiumPlanItem
                tier={SubscriptionTier.PROTECT}
                headLine={sectionTwoHeadline}
                title={sectionTwoTitle}
                paragraph={sectionTwoParagraph}
                ctaUrl={sectionTwoCtaurl}
                ctaText={sectionTwoCta}
              />
            </div>
          )}
        </div>
      </div>
    </Container>
  );
};

export default Renderer({ name: 'moduleFeaturedPlans' })(PremiumPlans);
