import cn from 'classnames';
import React, { FC } from 'react';

import ImageOrVideoItem from '@components/common/types/ImageOrVideoItem';
import { ImageOrVideo, Link, Text } from '@components/ui';

import s from './PlanItem.module.scss';

interface Props {
  source: ImageOrVideoItem;
  mobileSource?: ImageOrVideoItem;
  flickSource: ImageOrVideoItem;
  flickMobileSource?: ImageOrVideoItem;
  className?: string;
  useWhiteText: boolean;
  name: string;
  description: string;
  url: string;
}

const PlanItem: FC<Props> = ({
  source,
  flickSource,
  mobileSource,
  flickMobileSource,
  className,
  useWhiteText,
  name,
  description,
  url,
}) => {
  return (
    <div className={cn(className, 'relative', s.root)}>
      <ImageOrVideo {...source} className={cn(source.className, s.itemImage, s.mainImage)} />
      <ImageOrVideo {...flickSource} className={cn(flickSource.className, s.itemImage, s.flickImage)} />
      {mobileSource && (
        <ImageOrVideo
          {...mobileSource}
          className={cn(mobileSource.className, s.itemImage, s.mobileImage, s.mainImage)}
        />
      )}
      {flickMobileSource && (
        <ImageOrVideo
          {...flickMobileSource}
          className={cn(flickMobileSource.className, s.itemImage, s.flickImage, s.mobileImage)}
        />
      )}

      <div className="absolute inset-0 w-full h-full">
        <Link href={url || '#'} prefetch={false}>
          <span className={s.contentRoot}>
            <Text variant="heading-3" className={cn(useWhiteText ? s.whiteName : s.name, 'self-start')}>
              {name}
            </Text>
            <Text
              variant="text-2"
              weight="bold"
              className={`${useWhiteText ? s.whiteName : s.name} self-end ${s.description}`}
            >
              {description}
            </Text>
          </span>
        </Link>
      </div>
    </div>
  );
};

export default PlanItem;
